import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import fetch from 'cross-fetch'
import { setContext } from '@apollo/client/link/context'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const httpLink = new HttpLink({
  uri: publicRuntimeConfig.apiHost + '/graphql',
  fetch,
})
const authLink = setContext((_, { headers }) => {
  const { authToken } = JSON.parse(localStorage.getItem('asanarebel.user'))

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${authToken}`,
    },
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})

export default client
