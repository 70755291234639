import React from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { useUser } from '../../context/UserContext'
import useApi from '../../hooks/useApi'

export default function LanguageNavItems() {
  const { t } = useTranslation('translation', {
    useSuspense: false,
    bindI18n: false,
  })

  const router = useRouter()
  const { updateUser } = useApi()
  const [user, setUser] = useUser()

  const dataProps = (language) => {
    return {
      'data-tracking-event-type': 'navigationClick',
      'data-tracking-element-type': 'navigation',
      'data-tracking-element-name': language,
    }
  }

  const supportedLanguages = [
    {
      name: t('header.english'),
      value: 'en',
    },
    {
      name: t('header.german'),
      value: 'de',
    },
    {
      name: t('header.french'),
      value: 'fr',
    },
    {
      name: t('header.italian'),
      value: 'it',
    },
    {
      name: t('header.spanish'),
      value: 'es',
    },
    {
      name: t('header.portugese'),
      value: 'pt',
    },
  ]

  const setLanguage = async (language) => {
    const { errors } = await updateUser({ language })

    if (errors) {
      console.log(errors)
    } else {
      setUser({ ...user, language })

      router.reload(window.location.pathname)
    }
  }

  return (
    <>
      {supportedLanguages.map((language, index) => (
        <a
          key={index}
          {...dataProps(`language_${language.value}`)}
          onClick={() => setLanguage(language.value)}
          className={`whitespace-no-wrap ml-2 block px-4 hover:text-ar-light-gray ${
            index === 0 ? 'mt-0' : 'mt-2'
          }`}
          href="#">
          {language.name}
        </a>
      ))}
    </>
  )
}
