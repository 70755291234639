import getConfig from 'next/config'
import * as Sentry from '@sentry/nextjs'
import dayjs from 'dayjs'
import { useUser } from '../context/UserContext'

export default function useApi() {
  const [user] = useUser()
  const { publicRuntimeConfig } = getConfig()
  const { authToken, id } = user

  const request = async (method, path, data, token = authToken) => {
    let requestInfo

    try {
      requestInfo = {
        method,
        headers: {
          'Content-Type': 'application/json',
          'X-Platform': 'web',
          'X-Time-With-TimeZone': dayjs().format(),
        },
      }

      if (token) {
        requestInfo.headers['Authorization'] = `Bearer ${token}`
      }

      if (method !== 'GET') {
        requestInfo.body = JSON.stringify(data || {})
      }

      const res = await fetch(
        `${publicRuntimeConfig.apiHost}${path}`,
        requestInfo,
      )

      return res.status === 204 ? {} : res.json()
    } catch (error) {
      console.error(error)
      Sentry.captureException(error, {
        extra: { headers: requestInfo.headers },
      })
    }
  }

  const addToFavorites = ({
    listableType: listable_type,
    listableId: listable_id,
  }) =>
    request('POST', '/mobile_api/v3/lists/9999/list_item', {
      listable_type,
      listable_id,
    })

  const removeFromFavorites = ({
    listableType: listable_type,
    listableId: listable_id,
  }) =>
    request('DELETE', `/mobile_api/v3/lists/9999/list_item/${listable_id}`, {
      listable_type,
      listable_id,
    })

  const createGuestUser = () =>
    request('POST', '/api/v2/guest_users', { platforms: ['web'] })

  const googleLogin = ({
    first_name,
    last_name,
    email,
    id,
    id_token,
    authToken,
  }) =>
    request(
      'POST',
      '/api/v1/oauth',
      {
        first_name,
        last_name,
        email,
        google_id: id,
        google_id_token: id_token,
      },
      authToken,
    )

  const facebookLogin = ({
    first_name,
    last_name,
    gender,
    email,
    id,
    accessToken,
    authToken,
  }) =>
    request(
      'POST',
      '/api/v1/oauth',
      {
        first_name,
        last_name,
        sex: gender,
        email,
        facebook_id: id,
        facebook_access_token: accessToken,
      },
      authToken,
    )

  const appleLogin = ({ id_token, authToken }) =>
    request('POST', '/api/v1/oauth', { apple_token: id_token }, authToken)

  const loginUser = ({ email, password }) =>
    request('POST', '/api/v1/sessions', { email, password })

  const resetPassword = ({ email }) =>
    request('POST', '/api/v1/password_resets', { email })

  const magicLogin = ({ token }) =>
    request('POST', '/api/v1/magic_login', {
      token,
      authentication_token: publicRuntimeConfig.magicLoginApiToken,
    })

  const magicLoginToken = () => request('GET', '/api/v1/magic_login')

  const updateUser = ({ language }) =>
    request('PUT', `/mobile_api/v3/users/${id}`, { language })

  const showUser = () => request('GET', `/mobile_api/v3/users/${id}`)

  const createPlayback = ({ workoutId, source, sourceId }) =>
    request('POST', '/mobile_api/v3/playbacks', {
      completed: false,
      progress: 0,
      session_id: workoutId,
      session_type: 'V2::Workout', // might change later when we have more than just workouts
      source,
      sourceId,
    })

  const updatePlayback = ({ progress, playbackId }) =>
    request('PUT', `/mobile_api/v3/playbacks/${playbackId}`, {
      completed: progress >= 0.8,
      progress,
    })

  return {
    addToFavorites,
    removeFromFavorites,
    loginUser,
    googleLogin,
    facebookLogin,
    appleLogin,
    resetPassword,
    magicLogin,
    magicLoginToken,
    updateUser,
    showUser,
    createGuestUser,
    createPlayback,
    updatePlayback,
  }
}
