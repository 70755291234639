import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useUser } from '../../../context/UserContext'
import Cross from '../../../public/icons/cross.svg'

export default function SubscriptionInfoModal({ onClose }) {
  const { t } = useTranslation('translation', {
    useSuspense: false,
    bindI18n: false,
  })

  const [user] = useUser()

  const {
    subscription: { renewalDate, durationInWeeks },
  } = user

  const intervalLength = () => Math.round(parseInt(durationInWeeks) / 4.3)

  const formattedRenewalDate = () => {
    let date = new Date(renewalDate)

    return date.toLocaleString({
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
  }

  const autoRenewalText = () => {
    return renewalDate
      ? t('subscription_info.renewal', {
          date: formattedRenewalDate(),
        })
      : t('subscription_info.not_renewing')
  }

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div
        className="fixed inset-0 transition-opacity"
        aria-hidden="true"
        onClick={onClose}>
        <div className="absolute inset-0 bg-gray-800 opacity-75" />
      </div>
      <div className="flex min-h-screen items-center justify-center px-8 text-center sm:block sm:p-0">
        <span
          className="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true">
          &#8203;
        </span>
        <div
          className="inline-block w-full transform overflow-hidden rounded-2xl bg-ar-white text-left shadow-2xl transition-all sm:my-8 sm:align-middle md:w-1/2"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline">
          <div className="text-md p-10 text-center font-link text-ar-dark-gray">
            <p className="font-link">{t('subscription_info.title')}</p>
            <p className="p-5 text-4xl">❤️</p>
            <p className="font-link">
              {t('subscription_info.plan', { count: intervalLength() })}
            </p>
            <p className="font-body">{autoRenewalText()}</p>
            <br />
            <a
              className="font-body text-sm text-ar-dark-green"
              target="_blank"
              rel="noreferrer"
              href="https://asanarebel.zendesk.com/hc/requests/new"
              dangerouslySetInnerHTML={{
                __html: t('subscription_info.help'),
              }}
            />
          </div>
          <span
            className="absolute top-0 right-1 m-5 cursor-pointer font-bold text-ar-dark-gray hover:opacity-75"
            onClick={onClose}>
            <Cross />
          </span>
        </div>
      </div>
    </div>
  )
}

SubscriptionInfoModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}
