import React from 'react'
import { useTranslation } from 'react-i18next'
import Link from 'next/link'

const ADJUST_LINK = 'https://app.adjust.com/d0ud0kd'

export default function Footer() {
  const { t } = useTranslation('translation', {
    useSuspense: false,
    bindI18n: false,
  })

  const navItems = [
    {
      href: 'https://asanarebel.com/terms-of-use/',
      name: t('footer.terms_and_conditions'),
      target: '_blank',
    },
    {
      href: 'https://asanarebel.com/privacy-policy-2/',
      name: t('footer.privacy_policy'),
      target: '_blank',
    },
    {
      href: 'https://asanarebel.com/imprint/',
      name: t('footer.imprint'),
      target: '_blank',
    },
    {
      href: 'https://help.asanarebel.com/hc/en-us',
      name: t('footer.help_center'),
      target: '_blank',
    },
    {
      href: ADJUST_LINK,
      name: t('footer.download_app'),
      target: '_self',
    },
  ]

  return (
    <>
      <footer className="container mx-auto mt-32 mb-10 hidden justify-center px-4 font-body md:flex ">
        <span className="ml-8 uppercase text-ar-light-gray opacity-60">
          © 2021 ASANA REBEL GMBH
        </span>
        {navItems.map((navItem, index) => (
          <Link href={navItem.href} key={index}>
            <a
              target={navItem.target}
              className="ml-8 uppercase text-ar-light-gray opacity-60 hover:opacity-100">
              {navItem.name}
            </a>
          </Link>
        ))}
      </footer>
    </>
  )
}
