import PropTypes from 'prop-types'
import { ApolloProvider } from '@apollo/client'
import { useRouter } from 'next/router'
import { I18nextProvider } from 'react-i18next'
import { useEffect, useMemo } from 'react'
import client from '../../../graphql/apolloClient'
import useLocalStorageState from '../../../hooks/useLocalStorageState'
import UserContext from '../../../context/UserContext'
import i18n from '../../../i18n'

const UNPROTECTED_PATHS = [
  '/login',
  '/login/email',
  '/download-the-app',
  '/forgot-password',
  '/magic-login',
]

export default function BaseLayout({ children }) {
  const userState = useLocalStorageState('asanarebel.user', {})
  const router = useRouter()
  const user = userState[0]

  const shouldRedirectToLogin = useMemo(() => {
    return (
      Object.keys(user).length === 0 &&
      !UNPROTECTED_PATHS.includes(router.pathname)
    )
  }, [user, router])

  useEffect(() => {
    if (shouldRedirectToLogin) {
      router.push('/login')
    }
  }, [user, router, shouldRedirectToLogin])

  i18n.changeLanguage(user.language)

  if (!shouldRedirectToLogin) {
    return (
      <>
        <I18nextProvider i18n={i18n}>
          <UserContext.Provider value={userState}>
            <ApolloProvider client={client}>{children}</ApolloProvider>
          </UserContext.Provider>
        </I18nextProvider>
      </>
    )
  } else return <></>
}

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
