import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import Chevron from '../../public/icons/chevron.svg'

export default function RowHeading({
  children,
  className,
  redirectPath,
  contentLength,
}) {
  const router = useRouter()

  const redirectToSingleView = () => {
    router.push({
      pathname: redirectPath,
    })
  }

  const dynamicOnClick = () => {
    if (contentLength >= 10) {
      return {
        onClick: () => redirectToSingleView(),
      }
    }

    return {}
  }

  const clickable = () => Object.keys(dynamicOnClick()).length > 0

  return (
    <h2
      {...dynamicOnClick()}
      className={`${className || ''} my-8 font-link text-xl text-ar-dark-gray ${
        clickable() ? 'cursor-pointer hover:opacity-80' : ''
      } flex items-center justify-start gap-2`}>
      {children}
      {clickable() && (
        <Chevron className="-rotate-90 text-ar-dark-gray hover:opacity-80" />
      )}
    </h2>
  )
}

RowHeading.propTypes = {
  children: PropTypes.node.isRequired,
  redirectPath: PropTypes.string,
  className: PropTypes.string,
  contentLength: PropTypes.number,
}
