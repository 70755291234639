import { createContext, useContext } from 'react'

const UserContext = createContext({})

const extractUserData = (remoteUser) => {
  return {
    id: remoteUser.id,
    name: remoteUser.name,
    firstName: remoteUser.first_name,
    lastName: remoteUser.last_name,
    language: remoteUser.language,
  }
}

const extractSubscriptionData = (remoteSubscription) => {
  if (!remoteSubscription) return {}

  let renewalDate = remoteSubscription?.is_auto_renewing
    ? remoteSubscription?.expires_date
    : null

  return {
    subscription: {
      renewalDate: renewalDate,
      durationInWeeks: remoteSubscription?.duration_in_weeks,
    },
  }
}

export const createUserObject = (remoteUser, authToken) => {
  let userData = extractUserData(remoteUser)
  let subscriptionData = extractSubscriptionData(remoteUser.latest_subscription)
  let tokenData = { authToken: authToken }

  return Object.assign(userData, subscriptionData, tokenData)
}

export const useUser = () => useContext(UserContext)

export default UserContext
