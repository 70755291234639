import PropTypes from 'prop-types'
import BaseLayout from '../Base'
import Header from '../../Header'
import Footer from '../../Footer'

export default function HeaderLayout({ children }) {
  return (
    <BaseLayout>
      <Header />
      <main>{children}</main>
      <Footer />
    </BaseLayout>
  )
}

HeaderLayout.propTypes = {
  children: PropTypes.node.isRequired,
}
