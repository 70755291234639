/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import Logo from '../../public/icons/logo.svg'
import Menu from '../../public/icons/menu.svg'
import Cross from '../../public/icons/cross.svg'
import LogoWithTitle from '../../public/icons/logo_with_title.svg'
import Chevron from '../../public/icons/chevron.svg'
import ChevronClose from '../../public/icons/chevron-close.svg'
import LanguageNavItems from '../../components/LanguageNavItems'
import SubscriptionInfoModal from '../Modal/SubscriptionInfo'
import { useUser, createUserObject } from '../../context/UserContext'
import useApi from '../../hooks/useApi'

export default function Header() {
  const [user, setUser] = useUser()
  const { showUser } = useApi()

  const { t } = useTranslation('translation', {
    useSuspense: false,
    bindI18n: false,
  })

  const [activeMobileMenu, setActiveMobileMenu] = useState(false)
  const [activeLanguageMenu, setActiveLanguageMenu] = useState(false)
  const [activeLanguageMenuForMobile, setActiveLanguageMenuForMobile] =
    useState(false)

  const [showModal, setShowModal] = useState(false)
  const router = useRouter()

  const { language, subscription } = user

  const showLanguage = () => {
    var userLanguage = language || 'en'

    return `${t('header.language')}(${userLanguage})`
  }

  const handleSearch = async (event) => {
    event.preventDefault()

    router.push({
      pathname: '/search',
      query: { q: event.target.search.value },
    })

    event.target.children[0].value = ''
  }

  const logOut = () => {
    setUser({})

    router.push('/login')
  }

  const dataProps = (elementName) => {
    return {
      'data-tracking-event-type': 'navigationClick',
      'data-tracking-element-type': 'navigation',
      'data-tracking-element-name': elementName,
    }
  }

  const profileNavItems = [
    {
      href: '#',
      name: t('header.subscription_info'),
      onClick: () => setShowModal(true),
      show: subscription,
      element_name: 'subscription_info',
    },
    {
      target: 'https://app.adjust.com/d0ud0kd',
      href: '/download-the-app',
      name: t('header.download_the_app'),
      show: true,
      element_name: 'download_the_app',
    },
    {
      href: '#',
      name: t('header.logout'),
      show: true,
      onClick: () => logOut(),
      element_name: 'logout',
    },
  ]

  const navItems = [
    {
      href: '/',
      name: t('header.home'),
      element_name: 'home',
    },
    {
      href: '/exercise',
      name: t('header.exercise'),
      element_name: 'exercise',
    },
    {
      href: '/challenges',
      name: t('header.challenges'),
      element_name: 'challenges',
    },
  ]

  useEffect(() => {
    const updateUser = async () => {
      const remoteUser = await showUser()
      setUser(createUserObject(remoteUser, user.authToken))
    }
    updateUser()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {showModal && (
        <SubscriptionInfoModal onClose={() => setShowModal(false)} />
      )}
      <header className="sticky top-0 z-10 w-screen bg-white pb-0.5">
        <div className="container mx-auto flex flex-wrap px-4 pt-9 md:mb-10">
          <span className="absolute right-0 block lg:hidden">
            <button
              className="mt-2 mr-8 flex items-center px-3"
              id="navbar-btn"
              onClick={() => setActiveMobileMenu(!activeMobileMenu)}>
              {activeMobileMenu ? <Cross /> : <Menu />}
            </button>
          </span>

          <span className="hidden lg:block">
            <a href={'/'}>
              <LogoWithTitle
                className="cursor-pointer"
                {...dataProps('asanarebel_logo')}
              />
            </a>
          </span>

          <span className="lg:hidden">
            <a href={'/'}>
              <Logo
                className="cursor-pointer"
                {...dataProps('asanarebel_logo')}
              />
            </a>
          </span>

          <hr className="mt-8 flex w-full border-t md:hidden lg:mt-0 lg:border-0" />

          <div
            className={`block w-full flex-grow lg:ml-20 lg:flex lg:w-auto ${
              activeMobileMenu ? '' : 'hidden'
            }`}
            id="navbar">
            <div className="mt-4">
              {navItems.map((navItem, index) => (
                <a
                  key={index}
                  href={navItem.href}
                  {...dataProps(navItem.element_name)}
                  onClick={() => setActiveMobileMenu(false)}
                  className={`ml-4 block text-sm text-ar-light-gray hover:opacity-100 lg:mt-0 lg:inline-block ${
                    activeMobileMenu ? 'mt-5' : ''
                  }
                    ${
                      navItem.href === router.pathname
                        ? 'opacity-100'
                        : 'opacity-60'
                    }
                    `}>
                  {navItem.name}
                </a>
              ))}
            </div>

            <div className="relative right-0 mt-2 text-center lg:ml-auto lg:flex lg:text-right">
              <div className="relative hidden flex-1 lg:block">
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                  <button
                    type="submit"
                    className="focus:shadow-outline p-1 focus:outline-none">
                    <svg
                      fill="none"
                      stroke="#94A3B8"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      className="h-5 w-5">
                      <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                    </svg>
                  </button>
                </span>
                <form onSubmit={handleSearch}>
                  <input
                    id="search"
                    type="search"
                    className="rounded-md bg-ar-light-gray bg-opacity-10 py-2 pl-10 focus:outline-none"
                    placeholder={t('header.search')}
                    autoComplete="off"
                  />
                </form>
              </div>
            </div>

            <div className="hidden lg:block">
              <div className="dropdown relative ml-4 md:mt-4">
                <button className="inline-flex items-center rounded text-ar-light-gray">
                  <div className="flex">
                    <span className="text-sm">{t('header.profile')}</span>
                    <Chevron
                      className="mx-1 mt-2 text-sm"
                      height="4"
                      width="6"
                    />
                  </div>
                </button>

                <ul className="overflow dropdown-content absolute right-0 z-40 hidden w-48 rounded bg-white pt-1 text-sm text-ar-light-gray text-opacity-60">
                  <li className="dropdown ml-2">
                    <span className="whitespace-no-wrap block cursor-pointer px-4 py-2">
                      <div
                        className="flex hover:text-ar-light-gray"
                        onClick={() =>
                          setActiveLanguageMenu(!activeLanguageMenu)
                        }>
                        {showLanguage()}
                        {activeLanguageMenu ? (
                          <ChevronClose className="mx-1 mt-2 text-sm" />
                        ) : (
                          <Chevron
                            className="mx-1 mt-2 text-sm"
                            height="4"
                            width="6"
                          />
                        )}
                      </div>
                      <div
                        className={`${
                          activeLanguageMenu
                            ? 'mt-4 border-l border-ar-gray'
                            : 'hidden'
                        }`}>
                        <LanguageNavItems />
                      </div>
                    </span>
                  </li>
                  <li>
                    {profileNavItems.map((navItem, index) => {
                      if (navItem.show) {
                        return (
                          <a
                            key={index}
                            {...dataProps(navItem.element_name)}
                            className={`block px-4 py-2 ${
                              index === 0 ? 'mt-1' : ''
                            } whitespace-no-wrap ml-2 rounded-t hover:text-ar-light-gray ${
                              activeLanguageMenu ? 'mt-2' : ''
                            }`}
                            href={
                              navItem.href === '/download-the-app'
                                ? navItem.target
                                : navItem.href
                            }
                            onClick={() => {
                              setActiveMobileMenu(false)
                              navItem.onClick && navItem.onClick()
                            }}>
                            {navItem.name}
                          </a>
                        )
                      }
                    })}
                  </li>
                </ul>
              </div>
            </div>
            <div className="block text-sm text-ar-light-gray text-opacity-60 lg:hidden">
              <span className="whitespace-no-wrap block cursor-pointer px-4 py-2">
                <div
                  className="flex hover:text-ar-light-gray"
                  onClick={() =>
                    setActiveLanguageMenuForMobile(!activeLanguageMenuForMobile)
                  }>
                  {showLanguage()}
                  {activeLanguageMenuForMobile ? (
                    <ChevronClose className="mx-1 mt-2 text-sm" />
                  ) : (
                    <Chevron className="mx-1 mt-2 text-sm" />
                  )}
                </div>
                <div
                  className={`${
                    activeLanguageMenuForMobile
                      ? 'mt-4 border-l border-ar-gray'
                      : 'hidden'
                  }`}>
                  <LanguageNavItems />
                </div>
                {profileNavItems.map((navItem, index) => {
                  if (navItem.show) {
                    return (
                      <a
                        href={navItem.href}
                        key={index}
                        {...dataProps(navItem.element_name)}
                        className="whitespace-no-wrap mt-4 block rounded-t hover:text-ar-light-gray"
                        onClick={() => {
                          setActiveMobileMenu(false)
                          navItem.onClick && navItem?.onClick()
                        }}>
                        {navItem.name}
                      </a>
                    )
                  }
                })}
              </span>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}
